@import "node_modules/bootstrap/less/bootstrap";

// @import "./spark/spark";
@import "./../../../vendor/laravel/spark/resources/assets/less/spark";

@media (min-width: 1200px) {
    .container{
        max-width: 958px;
        width: 100%;
    }
}

.dsPresentation{
	height: 100vh;
	width: 100%;
}

.post-content{
	padding: 10px 20px;
	margin-bottom: 30px;
}

.post-content h2{
	font-weight: bolder;
	border-bottom: 1px dotted #f1f1f4;
	padding-bottom: 10px;
}

.post-content p{
	font-weight: regular;
	font-size: 1.1em;
}

.nm{
	margin: 0;
}

.nm .fa-2x{
	font-size: 1.5em;
}

.on{
	color: green;
}

.off{
	color: red;
}

.dataTables_paginate{
	text-align: right;
}

.dataTables_filter{
	text-align: right;
}

.dashboard-links{
	padding: 0;
	margin-bottom: 0;
}

.dashboard-links li {
    list-style-type: none;
}

.dashboard-links a {
    display: block;
    padding: 10px 15px;
    position: relative;
    border-bottom: 1px solid #e4ecf2;
    border-left: 3px solid transparent;
    color: #636b6f;
}

.dashboard-links li:last-child a {
    border-bottom: 0 none;
}

.page-wrapper{
	padding-top: 30px;
	min-height: calc(~"100vh - 70px");
	background-color: #f1f1f4;
}


.ggb{
	display: flex;
	align-items: center;
	justify-items: center;
	flex-direction: columns;
    background: #f1f1f4; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
	min-height: calc(~"100vh - 70px");
}

.vc{
	display: flex;
  	align-items: center;
  	justify-content: center;
  	flex-direction: column;
	min-height: calc(~"100vh");
}

.tl{
	font-weight: lighter;
}

.err-message{
	font-weight: lighter;
}

.err-contact{
	font-weight: lighter;
	font-size: 1em;
}

.btn-transparent{
	border: 1px solid #fff;
	color: #fff;
	border-radius: 4px;
	padding: 0 8px;
}

.orange{
	

background: #e53935; /* fallback for old browsers */
background: -webkit-linear-gradient(to left, #e53935 , #e35d5b); /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to left, #e53935 , #e35d5b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        
}

.grey{

background: #8e9eab; /* fallback for old browsers */
background: -webkit-linear-gradient(to left, #8e9eab , #eef2f3); /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to left, #8e9eab , #eef2f3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                
}

.lightpink{


background: #DAE2F8; /* fallback for old browsers */
background: -webkit-linear-gradient(to left, #DAE2F8 , #D6A4A4); /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to left, #DAE2F8 , #D6A4A4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        
}

.blue{


background: #e96443; /* fallback for old browsers */
background: -webkit-linear-gradient(to left, #e96443 , #904e95); /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to left, #e96443 , #904e95); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
              
}

.green{
	background: #02AAB0; /* fallback for old browsers */
	background: -webkit-linear-gradient(to left, #02AAB0 , #00CDAC); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to left, #02AAB0 , #00CDAC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.darkgreen{
	background: #136a8a; /* fallback for old browsers */
	background: -webkit-linear-gradient(to left, #136a8a , #267871); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to left, #136a8a , #267871); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.maroon{
	background: #556270; /* fallback for old browsers */
	background: -webkit-linear-gradient(to left, #556270 , #FF6B6B); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to left, #556270 , #FF6B6B); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.purple{	
	background: #9D50BB; /* fallback for old browsers */
	background: -webkit-linear-gradient(to left, #9D50BB , #6E48AA); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to left, #9D50BB , #6E48AA); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bluishgrey{
	background: #606c88; /* fallback for old browsers */
	background: -webkit-linear-gradient(to left, #606c88 , #3f4c6b); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to left, #606c88 , #3f4c6b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.dashboard{
	background-color: #333;
}

.grey{
	background-color: #333;
}

.navbar{
    font-size: 15px;
    margin: 0 auto;
    max-width: 958px;
    width: 100%;
	border-radius: 0;
	z-index: 1000;
}

// nav bar
.navbar-inverse {
    background-color: transparent;
    border: none;
}

.navbar-inverse .navbar-nav > li > a {
	font-weight: lighter;
    padding-left: 10px;
    padding-right: 10px; 
    color: rgba(255,255,255, 0.7);
}

.navbar-inverse .navbar-nav > li > a:hover {
    color: #fff;
}

header{
	background-color: transparent;
    position: relative;
}

header h1{
  color: #ffffff;
  font-size: 24px;
  font-weight: normal;
  line-height: 37px;
}

header .vc{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	min-height: calc(~"100vh - 190px");
	max-height: 800px;
}

.aut{
	font-size: 16px;
	line-height: 30px;
	margin-bottom: 36px;
}

.bl{
	border-left: 5px solid #84F0E0;
	padding-left: 20px;
}

header p{
	color: rgba(255, 255, 255, 0.74);
	font-size: 19px;
	line-height: 29px;
	margin-bottom: 36px;
}

section{
	background-color: #fff;
}

.section--default{
	min-height: 100px;
	padding: 50px 0;
}

.section--grey{
	min-height: 100px;
	padding: 50px 0;
	background-color: #f1f1f4;
}

.info--band{
	padding: 5px;
	border-bottom: 2px solid #f1f1f4;
}

.info--band p{
	font-size: 14px;
	margin-top: 5px;
}

.s_icon{
	width: 60px;
	margin-bottom: 20px;
	padding: 10px 5px;
	border-radius: 4px;
}

.media-body{
	padding: 50px 10px;
}

//footer
.footer-nav{
	padding-top: 30px;
	padding-bottom: 30px;
	min-height: 300px;
}

.primary-footer{
	background-color: #333;
	color: #fff;
	min-height: 200px;
	padding: 30px 0 50px 0;
}

.primary-footer a{
	color: #f1f1f4;
}

.primary-footer .logo{
	font-weight: bold;
	font-size: 32px;
}

.secondary-footer{
	background-color: #f1f1f4;
	padding: 20px 0;
}
.media-box{
	padding: 30px;
	color: #fff;
	border-radius: 4px;
	margin-top: 30px;
	margin-bottom: 30px;
	background-color: rgba(0,0,0,0.75);
}

.media-box3{
	padding: 30px;
	color: #fff;
	border-radius: 4px;
	background-color: rgba(0,0,0,0.75);
}

.media-box2{
	padding: 100px 30px 30px 30px;
	color: #fff;
	border-radius: 4px;
	margin-top: 30px;
	margin-bottom: 30px;
}

.media-box2 img{
	fill: #fff;
}

.mini-header{
	height: calc(~"50vh");
	min-height: 300px;
	max-height: 800px;
	background-color: transparent;
    position: relative;
}

.mini-vc{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	height: calc(~"50vh");
}

.mini-vc-left{
	display: flex;
	align-items: left;
	justify-content: center;
	flex-flow: column;
	height: calc(~"50vh");
}

.mini-vc-right{
	display: flex;
	align-items: flex-end;
	justify-content: center;
	flex-flow: column;
	height: calc(~"50vh");
}

.mini-vc h3{
	color: #fff;
}

.mini-header h1{
  font-weight: 300;
  line-height: 37px;
  text-align: left;
  float: left;
}

.mini-header p{
	font-weight: lighter;
	line-height: 29px;
	margin-bottom: 36px;
}

.cursive{
	font-family: "Dancing Script",cursive;
}

.services--block{
	height: calc(~"60vh");
	min-height: 300px;
	max-height: 800px;
}

.dropdown-menu {
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 0px;
}

.dropdown-menu > li > a {
	color: #f1f1f4;
}

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
    background-color: rgba(0,0,0,0.4);
    color: #fff;
}

.question{
	border: 1px solid #f1f1f4;
	margin: 30px 0;
	border-radius: 4px;
	padding: 40px;
}

.question h3{
	margin: 0;
	font-weight: lighter;
	text-align: center;
	font-size: 20px;
}

.contact--card{
	border-radius: 2px;
	background-color: #f1f1f4;
	position: relative;
	margin-top: -150px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.card{
	padding: 20px 20px;
}

.card h4{
	color: #000;
}

.card i{
	color: #000;
}

.lead{
	margin: 20px;
	font-size: 36px;
	font-weight: lighter;	
}

.dark--text{
	color: #000 !important;
	line-height: 30px;
	text-transform: justify-content;
}

h4{
	line-height: 30px;
}

.restaurant-bg{
	background-color: #000000;
	background-image: url("/img/restaurant.jpg");
  	background-repeat:no-repeat;
  	background-size: auto 100%;
  	background-position:center top;
}

.scripting-bg{
	background-color: #fff;
	background-image: url("/img/scripting.png");
  	background-repeat:no-repeat;
  	background-size: auto 100%;
  	background-position:center top;
}

.ecommerce-bg .media-box{
	padding: 30px;
	color: #fff;
	border-radius: 4px;
	margin-top: 30px;
	margin-bottom: 30px;
	background-color: rgba(0,0,0,0.4);
}

.ecommerce-bg{
	background-color: #000000;
	background-image: url("/img/ecommerce.png");
  	background-repeat:no-repeat;
  	background-size: auto 100%;
  	background-position:center top;
}

.ecommerce-bg{
	background-color: #000000;
	background-image: url("/img/ecommerce.png");
  	background-repeat:no-repeat;
  	background-size: auto 100%;
  	background-position:center top;
}

.restaurant-tb{
	color: white;
}

.restaurant-vl{
	display: flex;
	justify-content: end;
	flex-flow: column;
}

.restaurant-vr{
	display: flex;
	justify-content: center;
	flex-flow: column;
}

.design-btn{
	background-color: #f1f1f4;
	color: #878585;
	border: 1px solid #f1f1f4;
	border-radius: 4px;
	padding: 5px 10px;
}

.white{
	background-color: white;
}

.clients--title {
    color: #666;
    font-size: 16px;
    font-style: italic;
    font-weight: 300 !important;
}

.wd--square{
	min-height: 350px;
	display: flex;
	justify-content: center;
	flex-flow: column;
}

.wd--square h2{
	font-weight: 400;
	font-size: 30px;
}

.wd--square p{
	font-weight: 200;
	font-size: 20px;
}

.wd--section h4{
	font-weight: 400;
	font-size: 30px;
	margin-bottom: 20px;
}

.wd--section p{
	font-weight: 200;
	font-size: 18px;
}

.wd--section h5{
	font-weight: 200;
	font-size: 16px;
}

.portfolio{
	min-height: 420px;
	background-color: #333;
}

.protfolio-content{
	min-height: 400px;
	padding: 50px;
}

.protfolio-content h2{
	color: #fff;
	font-weight: lighter;
}

.protfolio-content p{
	color: #fff;
	font-weight: lighter;
}

#main-wrapper{
	background-color: #f1f1f4;
	min-height: 100vh;
	padding: 0;
	margin: 0;
	-webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

#sidebar-wrapper{
	position: absolute;
	background-color: #f1f1f4;
	margin-left: -250px;
	left: 250px;
	min-height: 100vh;
	width: 250px;
	 -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.toggled #sidebar-wrapper{
	left: 0;
}

#content-wrapper{
	background-color: white;
	margin-left: 250px;
	min-height: 100vh;
	width:  calc(~"100vw - 250px");
	 -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.toggled #content-wrapper{
	margin-left: 0px;
	width:  calc(~"100vw");
}

.sidebar-nav {
    position: absolute;
    top: 0;
    width: 250px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar-nav li {
    text-indent: 20px;
    line-height: 40px;
}

.sidebar-nav li a {
    display: block;
    text-decoration: none;
    color: #999999;
}

.sidebar-nav li a:hover {
    text-decoration: none;
    color: #fff;
    background: #333;
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
    text-decoration: none;
    color: #fff;
    background: #333;
}

.bb{
	border-bottom: 1px solid #f1f1f4;
}

.brl{
	border-left: 1px solid #f1f1f4;
}

.bt{
	border-top: 1px solid #f1f1f4;
}

.br{
	border-right: 1px solid #f1f1f4;
}

.tech-heading{
	margin-top: 0px;
	padding-top: 0px;
	font-weight: 300;
	color: #0168B4;
	border-bottom: 1px solid #E1E1E1;
	padding-bottom: 20px;
	padding-left: 30px;
	padding-right: 30px;
}

.tech-content{
	font-weight: 200;
	color: #333;
	font-size: 16px;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 10px;
}

.rb{
	border: 1px solid #E1E1E1;
	border-radius: 4px;
	padding: 10px 0px 20px 0px;
}